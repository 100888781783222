import React from 'react';
import localize from '@src/i18n';
import cx from 'classnames';
import PropTypes from 'prop-types';
import s from './AdDisclaimer.scss';

const AdDisclaimer = ({ className }) => (
  <div className={cx({ [s.adDisclaimer]: true, [className]: className })}>
    <div className={s.adLine} />
    <div className={s.adTitle}>{localize('label_advertisement')}</div>
    <div className={s.adLine} />
  </div>
);

AdDisclaimer.propTypes = {
  className: PropTypes.string
};

export default AdDisclaimer;
