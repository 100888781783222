import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import transitions from '@src/utils/constants/transitions';

import ModalBase from '@comp/Modals/ModalBase';
import s from './ScaleModal.scss';

const cx = classNames.bind(s);

const ScaleModal = ({
  isOpen,
  children,
  onRequestClose,
  onAfterOpen,
  contentBaseClassName
}) => (
  <ModalBase
    isOpen={isOpen}
    className={{
      base: cx({ content: true, [contentBaseClassName]: contentBaseClassName }),
      afterOpen: s.contentAfterOpen,
      beforeClose: s.contentBeforeClose
    }}
    overlayClassName={{
      base: s.overlay,
      afterOpen: s.overlayAfterOpen,
      beforeClose: s.overlayBeforeClose
    }}
    onRequestClose={onRequestClose}
    onAfterOpen={onAfterOpen}
    closeTimeoutMS={transitions.FASTER}
  >
    {children}
  </ModalBase>
);

ScaleModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
  contentBaseClassName: PropTypes.string
};

export default ScaleModal;
