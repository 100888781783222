import { connect } from 'react-redux';
import { navbarActions } from '@ducks/navbar';
import Navbar from './Navbar';

const mapStateToProps = state => ({
  logo: state.navbar.logo,
  menu: state.navbar.currentMenu,
  menuExpanded: state.navbar.menuExpanded,
  isShowingPublicMenu: state.navbar.isShowingPublicMenu,
  redirectUrl: state.navbar.redirectUrl,
  product: state.config.product
});

const mapDispatchToProps = dispatch => ({
  toggleMainMenu: () => {
    dispatch(navbarActions.toggleMenu());
  },
  collapsMainMenu: () => {
    dispatch(navbarActions.collapsMenu());
  },
  expandItem: index => {
    dispatch(navbarActions.expandItem(index));
  }
});

const navbarContainer = connect(mapStateToProps, mapDispatchToProps)(Navbar);

export default navbarContainer;
