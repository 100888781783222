import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  VERYSMALL_MEDIA,
  SMALL_MEDIA,
  MEDIUM_MEDIA,
  LARGE_MEDIA
} from '@src/utils/constants/breakpoints';

const cx = classNames;
const blankPixel =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
const LAZY_CLASS = 'lazyload';

const ResponsiveImageDisplay = ({
  alt,
  className,
  imageUrls: { verySmallMediaUrl, smallMediaUrl, mediumMediaUrl, largeMediaUrl }
}) => (
  <figure className={className}>
    <picture>
      <source data-srcset={verySmallMediaUrl} media={VERYSMALL_MEDIA} />
      <source data-srcset={smallMediaUrl} media={SMALL_MEDIA} />
      <source data-srcset={mediumMediaUrl} media={MEDIUM_MEDIA} />
      <source data-srcset={largeMediaUrl} media={LARGE_MEDIA} />
      <img src={blankPixel} className={cx(className, LAZY_CLASS)} alt={alt} />
    </picture>
  </figure>
);

ResponsiveImageDisplay.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  imageUrls: PropTypes.shape({
    verySmallMediaUrl: PropTypes.string,
    smallMediaUrl: PropTypes.string,
    mediumMediaUrl: PropTypes.string,
    largeMediaUrl: PropTypes.string
  })
};

export default ResponsiveImageDisplay;
