import { connect } from 'react-redux';
import { modalsSelectors, modalsActions } from '@ducks/modals';
import ModalBase from './ModalBase';

const mapStateToProps = state => ({
  isAtLeastOneModalOpen: modalsSelectors.isAtLeastOneModalOpen(state.modals),
  product: state.config.product
});

const mapDispatchToProps = dispatch => ({
  resetOpening: () => {
    dispatch(modalsActions.setOpening(null));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBase);
