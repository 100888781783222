import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import s from './NeutralMessagePanel.scss';

const cx = classNames.bind(s);

const NeutralMessagePanel = ({ text, className, children }) => (
  <div className={cx({ container: true, [className]: className })}>
    <p className={s.paragraph}>{text}</p>
    {children}
  </div>
);

NeutralMessagePanel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  children: PropTypes.node
};

export default NeutralMessagePanel;
