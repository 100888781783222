import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  setScrollbar,
  checkScrollbar,
  resetScrollbar
} from '@src/utils/helpers/scrollBar';
import s from './ModalBase.scss';

Modal.setAppElement(
  process.env.NODE_ENV === 'test' ? document.body : '#react-placeholder'
);

// Component that extends react-modal functionality;
// - Prevent background scroll
// - Handle scrollbar width when locking UI
// - Unlock/remove scrollar adaptions when all modals of this instance are closed
// NB! react-modal prop `bodyOpenClassName` can handle UI lock, but does not handle
// scrollbar. Keep this is mind if/when refactoring.
class ModalBase extends Component {
  static lockBody() {
    document.body.classList.add(s.lock);
  }

  static unlockBody() {
    document.body.classList.remove(s.lock);
  }

  componentDidMount() {
    this.handleModalVisibility(this.props.isAtLeastOneModalOpen);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isAtLeastOneModalOpen !== prevProps.isAtLeastOneModalOpen ||
      this.props.isOpen !== prevProps.isOpen
    ) {
      this.handleModalVisibility(this.props.isAtLeastOneModalOpen);
    }
  }

  componentWillUnmount() {
    ModalBase.unlockBody();
  }

  onAfterOpen = () => {
    const { resetOpening, onAfterOpen } = this.props;
    resetOpening();
    return onAfterOpen && onAfterOpen();
  };

  setUILock = () => {
    ModalBase.lockBody();
  };

  handleModalVisibility = isAtLeastOneModalOpen => {
    if (isAtLeastOneModalOpen) {
      checkScrollbar();
      setScrollbar();
      this.setUILock();
    } else {
      resetScrollbar();
      this.removeUILock();
    }
  };

  removeUILock = () => {
    ModalBase.unlockBody();
  };

  render() {
    const {
      isOpen,
      children,
      onRequestClose,
      className,
      overlayClassName,
      closeTimeoutMS
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className={className}
        overlayClassName={overlayClassName}
        closeTimeoutMS={closeTimeoutMS}
        onRequestClose={onRequestClose}
        onAfterOpen={this.onAfterOpen}
        contentLabel="Modal"
      >
        {children}
      </Modal>
    );
  }
}

ModalBase.propTypes = {
  isOpen: PropTypes.bool,
  isAtLeastOneModalOpen: PropTypes.bool,
  children: PropTypes.node,
  onRequestClose: PropTypes.func,
  className: PropTypes.shape({}),
  overlayClassName: PropTypes.shape({}),
  closeTimeoutMS: PropTypes.number,
  onAfterOpen: PropTypes.func,
  resetOpening: PropTypes.func
};

export default ModalBase;
