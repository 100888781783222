import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import {
  recipeSearchActions,
  recipeSearchSelectors
} from '@ducks/recipeSearch';
import SearchAllRecipeArea from './SearchAllRecipeArea';

const DEBOUNCE_TIME = 500;

const mapStateToProps = state => ({
  searchResultItems: state.recipeSearch.recipeSearchItems,
  searchInputValue: state.recipeSearch.recipeSearchInputValue,
  rawSearchInputValue: state.recipeSearch.recipeRawSearchInputValue,
  isSearchEmpty: recipeSearchSelectors.isSearchEmpty(state.recipeSearch),
  isSearchValueAboveThreshold:
    recipeSearchSelectors.isSearchValueAboveThreshold(state.recipeSearch)
});

const mapDispatchToProps = dispatch => ({
  getSearchResult: debounce(
    ({
      searchString,
      rawSearchString,
      maxResult,
      wantedResponseAttributes,
      filters
    }) => {
      dispatch(
        recipeSearchActions.getSearchResult({
          searchString,
          rawSearchString,
          maxResult,
          isRawSearch: true,
          wantedResponseAttributes,
          filters
        })
      );
    },
    DEBOUNCE_TIME
  ),
  clearSearchResult: () => {
    dispatch(recipeSearchActions.clearSearchResult());
  },
  clearSearchInputValue: () => {
    dispatch(recipeSearchActions.clearInputValue());
  },
  clearRawSearchInputValue: () => {
    dispatch(recipeSearchActions.clearRawSearchInputValue());
  },
  setSearchInputValue: value => {
    dispatch(recipeSearchActions.setInputValue(value));
  },
  setRawSearchInputValue: value => {
    dispatch(recipeSearchActions.setRawSearchInputValue(value));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAllRecipeArea);
