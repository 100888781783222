import React from 'react';
import PropTypes from 'prop-types';
import ScaleModal from '@comp/Modals/ScaleModal';
import HealthDataConsent from '@comp/HealthDataConsent';
import s from './HealthDataConsentModal.scss';

const HealthDataConsentModal = ({ isOpen }) => (
  <ScaleModal isOpen={isOpen} contentBaseClassName={s.modal}>
    <HealthDataConsent />
  </ScaleModal>
);

HealthDataConsentModal.propTypes = {
  isOpen: PropTypes.bool
};

export default HealthDataConsentModal;
